/*!
* Description: this file contains a component
*  that renders a disabled lookalike button that contains numbers or text
*/

import React, { useState, useEffect,forwardRef } from 'react';
import loadable from '@loadable/component';
import withStyles from "@mui/styles/withStyles";
import guidesStyle from "assets/jss/incept-custom-css.jsx";
import axios from "axios";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import { isAuthenticated, is_g_comp } from "assets/jss/incept-sustainability-variables.jsx";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
//Translations
import { withTranslation, Trans,useTranslation } from 'react-i18next';
import i18n from "i18next";

const useStyles = (theme) => ({
	containerother: {
		paddingLeft: "40px",
		marginRight: "auto",
		marginLeft: "auto",
		[theme.breakpoints.down('md')]: {
			padding: "0 25px",
		},
	},
	wrapper: {
		/*marginTop: "-9.5rem",*/
		marginRight: "2rem",
		marginBottom: "40px",
		[theme.breakpoints.down('md')]: {
			margin: "0",
		},
	},
	titlepackage: {
		fontFamily: "Raleway,Helvetica,Arial,sans-serif",
		marginBottom: "1.5rem",
		margin: "0",
		paddingTop: "3rem",
		//marginLeft: "1.2rem"
	},
});

const ListModules = loadable(() => import('../Guides/ListModules'));
function KnowledgeBasePage(props) {
	const {match,classes,location, content} = props;
	const { id } = match.params;
	const [loading, setloading] = useState(true);
	const [nameKB, setnameKB] = useState("");
	const [all_modules, setall_modules] = useState([]);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		getkbmodules();
	  }, [id]);

	/*
	 * Getting info about guides
	 *
	 */
	const getkbmodules = async () => {
		setloading(true);
		if (!isAuthenticated()) {
			props.isExpired();
		}
		const token = localStorage.getItem("token");
		const insdata = {
			kbid: id
		};

		const authstr = "Bearer " + token;
		const useLang = i18n.language;
		axios
			.get(is_g_comp + "kbmodule?wpml_language=" + useLang, { headers: { Authorization: authstr } , params: insdata })
			.then((response) => {
				console.log(response);
				setloading(false);
				setnameKB(response.data.kbname);
				setall_modules(response.data.modules)
			})
			.catch((err) => {
				//this.error_ocurred("Error API "+is_g_comp+" "+err);
				//log.error("Error API "+is_g_comp+"kbmodule "+err);
				setloading(false);
			});
	}

	const button_word = (started,completed,t) => {
		return t("Open");
	}

	return (
		<LoadingOverlay 
		active={loading} 
		spinner={<BounceLoader />}>
			<div className={classes.containerother}>
				<div className={classes.wrapper}>
					<h2 className={classes.titlepackage}> {t('Knowledge Base')+": "+nameKB} </h2>
					<ListModules 
					list_modules={all_modules}
					showHideModal={false}
					button_word={button_word}
					iskb={true}
					startModule={"/1"}
					type={"/KB"+id+"/"}
					t={t} 
					/>
				</div>
			</div>
		</LoadingOverlay>
	);
}
export default
withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
	  ...guidesStyle(theme)
    }),
    { withTheme: true },
  )(KnowledgeBasePage);
