/*!
 * Description: this file contains a component
 * that renders a disabled lookalike button that contains numbers or text
 */

import React, {useEffect, useState} from "react";
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx"; //style for all analytics

import search from "assets/img/search.svg";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import MultiDimDropdown from 'components/CustomInput/MultiDimDropdown.jsx';

import DropDown from "components/CustomInput/DropDown.jsx";
import MenuItem from "@mui/material/MenuItem";
import ActionButton from "components/CustomButtons/ActionButton.jsx";
import FakeButton from "components/CustomButtons/FakeButton";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import { withTranslation, Trans,useTranslation } from 'react-i18next';

import { useMediaQuery } from "react-responsive"; //for mobile responsiveness

import { useIdeasFilterContext } from "../../../components/AnalyticsIdeas/context/IdeasFilterContext";

const useStyles = (theme) => ({
  paddingMobile: ({ color, textColor }) => ({
    "@media only screen and (max-width: 960px)": {
      margin: "20px 0",
    },
  }),
});

function withMyHook(Component) {
  return function WrappedComponent(props) {
    const mobile = useMediaQuery({ query: `(max-width: 1200px)` });
    return <Component {...props} isMobile={mobile} />;
  };
}

function AnalyticsFilterBar({AnalyticsIdeasHandleFilter, ...props}) {
	const {classes, content} = props;
	const [load, setLoad] = useState(false);
    const ideasFilter = useIdeasFilterContext();
    const { t, i18n } = useTranslation();

	let menuItem = [];

	if (props.labels && Array.isArray(props.labels)) {
        for(const label of props.labels) {
            menuItem.push(<MenuItem key={label.id} value={label.id} name={label.label}>{label.label}</MenuItem>);
        }
    }

	return (
        <div>
        {!props.isArchived && <GridContainer space={false} alignItems="center">
			<GridItem xs={12} sm={12} lg={12} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                    <span style={{fontWeight:"bold",marginRight:"20px"}}>{t("Filters by company attributes")+":"}</span>
            </GridItem>
            <GridItem xs={12} sm={12} md={9} lg={12} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                <GridContainer space={false} alignItems="center">
                    <GridItem xs={12} sm={12} lg={2} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"16px"}:{}}>
                        <div align={"left"}>
							<GridContainer direction="column">
								<GridItem otherclasses={classes.noLeftPadding}>
								<span style={{marginRight:"15px"}}>{t("Market")+": "}</span>
								</GridItem>
								<GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
								<DropDown
									value={ideasFilter.currentDepartment}
									analytics={true}
									paddingShort={true}
									fullWidth={false}
									otherClasses={classes.doubleLineDD}
									style={{width:"140px"}}
									onChange={(e) => props.filterSectionsDep(e)}
								>
									<MenuItem key={0} value={0}>{"All"}</MenuItem>
									{props.departments.map(
										(item) => <MenuItem key={item.id} value={parseInt(item.id)}>{item.name}</MenuItem>
									)}
								</DropDown>
								</GridItem>
							</GridContainer>
                        </div>
                    </GridItem>
					<GridItem xs={12} sm={12} lg={4} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem space={0} spaceNoPadding={true}>
                            <span style={{marginRight:"15px"}}>{t("Geography")+": "}</span>
                            </GridItem>
                            <GridItem space={0} spaceNoPadding={true}>
                            <MultiDimDropdown
                             options={props.countries}
                             keyname={"name"}
                             cat={"geography"}
                             filterSectionsCountry={ideasFilter.setCurrentCountry}
                             clearfilter={ideasFilter.setCurrentCountry}
                             selectedOptions={ideasFilter.currentCountry}
                             multiselectRef={ideasFilter.multiselectRef}
                             />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                    <GridItem xs={12} sm={12} lg={4} style={props.isMobile ? {marginTop:"0"}:{marginTop: '25px',marginBottom:"0" }}>
                        <ActionButton
                            type="submit"
                            isAnalytics={true}
                            onClick={props.searchFilter}
                        >
                            {t('Search')}
                        </ActionButton>
						<ActionButton
                            type="submit"
							style={{marginLeft:"20px"}}
                            backGround={false}
                            isAnalytics={true}
                            onClick={() => {props.clearFilters("clear", "2", "", ideasFilter)}}
                        >
                            {t('Clear')}
                        </ActionButton>
                    </GridItem>
                </GridContainer>
            </GridItem>
			<GridItem xs={12} sm={12} lg={12} otherclasses={classes.noLeftPadding} style={!props.isArchived ? {marginTop:"40px"}:{}}>
                    <span style={{fontWeight:"bold",marginRight:"20px"}}>{t("Other filters")+":"}</span>
            </GridItem>
            <GridItem xs={12} sm={12} md={9} lg={12} otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                <GridContainer space={false} alignItems="center">
					{props.modules.length>0 && <GridItem otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                        <GridContainer direction="column">
                            <GridItem otherclasses={classes.noLeftPadding}>
                            <span style={{marginRight:"15px"}}>{t("Module")+": "}</span>
                            </GridItem>
                            <GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"16px"}:{}}>
                            <DropDown
                                value={props.currentModule}
                                analytics={true}
                                paddingShort={true}
                                fullWidth={false}
                                style={{width:"170px"}}
								onChange={(e) => props.onChangeDep("module", e.target.value, ideasFilter.setCurrentModule, ideasFilter)}
                            >
                                <MenuItem key={"0"} value={"0"} name={""}>{"All"}</MenuItem>
                                {props.modules.map(
                                    (item) => <MenuItem key={item.id} value={item.id} name={item.name}>{item.name}</MenuItem>
                                )}
                            </DropDown>
                            </GridItem>
                        </GridContainer>
                    </GridItem>}
                    <GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
						<GridContainer direction="column">
							<GridItem otherclasses={classes.noLeftPadding}>
							<span style={{marginRight:"15px"}}>{t("Label")+": "}</span>
							</GridItem>
							<GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"16px"}:{}}>
							<DropDown
								value={props.currentLabel}
								analytics={true}
								paddingShort={true}
								fullWidth={false}
								style={{width:"140px"}}
								onChange={(e) => props.onChange("label", e.target.value, ideasFilter.setCurrentLabel, ideasFilter)}
							>
								<MenuItem key={0} value={"-1"}>{"All"}</MenuItem>
								<MenuItem key={1} value={"0"}>{"No label"}</MenuItem>
								{menuItem}
							</DropDown>
						</GridItem>
						</GridContainer>
                    </GridItem>
                    <GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {margin:"10px 0px 20px  0"}:{paddingLeft:"10px",height:"30px"}}>
						<GridContainer direction="column">
							<GridItem otherclasses={classes.noLeftPadding}>
							<CustomInput
								id="search-box"
								img={search}
								value={props.searchVal}
								isSearch={true}
								customMarginTop={"6px"}
								onChange={e => props.setsearch(e.target.value)}
								onKeyDown={props.searchWord}
								formControlProps={{
									fullWidth: false,
									disabled: false
								}}
							/>
						</GridItem>
						</GridContainer>
                    </GridItem>
                    <GridItem style={props.isMobile ? {marginTop:"0"}:{justifyContent: 'flex-start' }}>
                        <ActionButton
                            type="submit"
                            backGround={false}
                            isAnalytics={true}
                            onClick={() => props.clearFilters("clear","1", "" , ideasFilter)}
                        >
                            {t('Clear')}
                        </ActionButton>
                    </GridItem>
                </GridContainer>
            </GridItem>
			<GridItem xs={12} sm={12} lg={12} otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
				<div><i style={{fontSize:"11px",marginLeft:"0"}}>{t("Results won't show when is less than 5 per market")}</i></div>
            </GridItem>
        </GridContainer>}
		<GridContainer space={false} alignItems="center" style={{marginTop:"50px"}}>
            <GridItem otherclasses={classes.noLeftPadding} style={false ? {marginTop:"0"}:{}}>
                    <span style={{fontWeight:"bold",marginRight:"20px"}}>{t("With selected")+":"}</span>
            </GridItem>
            <GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"20px"}:{}}>
				<GridContainer space={false} alignItems="center">
					{(!props.isCustomManager && !props.isArchived) && <GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                        <ActionButton
                            type="submit"
                            isAnalytics={true}
							disabled={props.nonCheckedideas}
							onClick={props.addMyActionModal}
                        >
                            {'+'+t('add to My Actions')}
                        </ActionButton>
                    </GridItem>}
                    {(!props.isCustomManager && !props.isArchived) && <GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                        <ActionButton
                            type="submit"
                            isAnalytics={true}
							disabled={props.nonCheckedideas}
							onClick={props.addActionModal}
                        >
                            {t('Create new Action')}
                        </ActionButton>
                    </GridItem>}
					<GridItem otherclasses={classes.noLeftPadding} style={props.isMobile ? {marginTop:"0"}:{}}>
                        <ActionButton
                            type="submit"
                            isAnalytics={true}
							disabled={props.nonCheckedideas}
							onClick={props.archiveIdea}
                        >
                            {props.isArchived ? t('Remove from Archive'):t('Archive')}
                        </ActionButton>
                    </GridItem>
                </GridContainer>
            </GridItem>
        </GridContainer>
        </div>
	);
}

export default withMyHook(
  withStyles(
    (theme) => ({
      ...analyticsStyle(theme),
      ...useStyles(theme),
    }),
    { withTheme: true }
  )(AnalyticsFilterBar)
);
