/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState, memo, useEffect } from "react";
import withStyles from "@mui/styles/withStyles";

import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import {
	ourBlueLight,
	ourGreen,
	ourOrange,
} from "assets/jss/incept-sustainability-variables.jsx";

import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";

import CardAnalytics from "components/CardAnalytics/Card.jsx";
import CardAnalyticsHeader from "components/CardAnalytics/CardHeader.jsx";
import CardAnalyticsBody from "components/CardAnalytics/CardBody.jsx";
import { Link } from "react-router-dom";
import Checkbox from "@mui/material/Checkbox";

//import archive from "assets/img/archive.png";

import Tooltip from "@mui/material/Tooltip";

import { withTranslation, Trans, useTranslation } from "react-i18next";

import FakeButton from "components/CustomButtons/FakeButton";
import PaginatedItems from "components/Pagination/MinifiedPagination/Pagination";

const useStyles = (theme) => ({
	smallButton: ({ color, textColor }) => ({
		backgroundColor: color,
		color: textColor,
		border: "0px",
		borderRadius: "20px",
		fontSize: "14px",
		padding: "5px 30px",
		marginBottom: "0.5rem",
	}),
});

function findArrayElementByEdit(array, iddep) {
	return array.find((element) => {
		return element.id === iddep;
	});
}

const TextOnlyTooltip = withStyles({
	tooltip: {
	  fontSize: "14px",
	},
  })(Tooltip);

const IdeaCard = memo(({ idea, departments, editLabelModal, deleteLabelModal, addLabelModal, deleteIdeaModal, handleChangeCheckbox, selectedIdeas, indexIdea, classes, isArchived }) => {
	const [ideaLabel, setIdeaLabel] = useState(idea.label);

	useEffect(() => {
		setIdeaLabel(idea.label);
	}, [idea.label]);


	let pattern = /(\d{2})\-(\d{2})\-(\d{4})/;
		// let dt = new Date(idea.dateidea.replace(pattern, "$3-$2-$1"));
		//If there are actions based on this idea
		let actionbutton = "";
		if (idea?.actions?.length > 0) {
			const newTo = {
				pathname: "/admin/my_actions",
				search: "?idea=" + idea.ideaid
			};
			actionbutton = <Link target="_blank" to={newTo} ><FakeButton
				color={ourGreen}
				textColor={"rgba(0, 0, 0, 0.87)"}
				style={{
					fontWeight: "200",
					padding: "2px 20px 2px 20px",
					marginLeft: "10px",
					marginBottom: "5px",
				}}
				disabledb={false}
				content={"Action ("+idea.actions.length+")"}
			/></Link>;
		}
		//Fake buttons for labels
		const labelsbuttons = ideaLabel.map((label, index) => (
			<FakeButton
				key={index}
				color={ourBlueLight}
				textColor={"rgba(0, 0, 0, 0.87)"}
				labelIcon={true}
				onTextClick={(e) => editLabelModal(e, label, idea.ideaid, index)}
				ShowModalFunc={(e) =>
					deleteLabelModal(e, label.name, index, idea.ideaid, label.id)
				} //I think it should be 'true' instead of "e"
				style={{
					fontWeight: "200",
					padding: "2px 10px 2px 20px",
					marginLeft: "10px",
					marginBottom: "5px",
				}}
				content={label.name}
			/>
		));
		let depname = findArrayElementByEdit(departments, idea.departmentid);
		let department = typeof depname === "undefined" ? "" : depname.name;
		return (
			<GridItem
				xs={12}
				sm={12}
				md={6}
				lg={4}
				otherclasses={classes.columnCardsPadding}
				key={idea.ideaid}
				id={idea.ideaid}
			>
				<CardAnalytics style={{ height: "100%" }}>
					<CardAnalyticsHeader
						title={"Received " + idea.dateidea}
						subtitle={""}
						alignCenter={false}
						source={
							idea.module !== null ? idea.module + " module" : "Dashboard"
						}
						activitySource={idea.activityName}
						exerciseid={idea.fromactivity}
						department={department}
						isjustifyContent={true}
						hrNoMarginBottom={{ marginBottom: "0" }}
					>
						{/*<div
							style={{height:"30px", display:"flex", alignItems:"center",marginRight:"7px",cursor:"pointer"}}>
							<TextOnlyTooltip title={"Archive idea"} arrow>
							<img
									src={archive}
									alt="archive icon"
									style={{ width: "18px", height: "18px" }}
								/>
							</TextOnlyTooltip>
					</div>*/}
						{(idea.activityName!=="" || isArchived) && <div className={"deletebutton"} style={{width:"30px",marginRight:"5px"}} onClick={(e) => deleteIdeaModal(e,idea.ideaid,idea.description)}>
							<TextOnlyTooltip title={"Delete idea"} arrow>
							<a href="#">
								<span className={"left"}>
								<span className={"circle-left"}></span>
								<span className={"circle-right"}></span>
								</span>
								<span className={"right"}>
								<span className={"circle-left"}></span>
								<span className={"circle-right"}></span>
								</span>
							</a>
							</TextOnlyTooltip>
						</div>}
						<Checkbox
							color="default"
							disableRipple
							checked={(typeof selectedIdeas[indexIdea]==="boolean") ? selectedIdeas[indexIdea]:false}
							value={indexIdea}
							style={{ color: ourOrange, alignItems: "initial", paddingTop: "3px", height: "30px", width: "30px", marginRight: "-10px" }}
							onChange={(e) => handleChangeCheckbox(e, idea.ideaid, indexIdea)}
							className={classes.radioButton}
						/>
					</CardAnalyticsHeader>
					<CardAnalyticsBody>
						<div>
							<span style={{ margin: "5px 0" }}>Labels:</span>
							{actionbutton}
							{idea.label.length > 0 && labelsbuttons}
							<FakeButton
								color={"white"}
								border={2}
								disabledb={false}
								borderColor={ourBlueLight}
								textColor={"rgba(0, 0, 0, 0.87)"}
								onClick={(e) => addLabelModal(e, idea.ideaid, indexIdea)}
								style={{
									fontWeight: "200",
									padding: "2px 15px",
									marginLeft: "10px",
									marginBottom: "5px",
									color: ourOrange,
									cursor: "pointer",
								}}
								content={"+"}
							/>
						</div>
						<div style={{ marginTop: "20px" }}>{idea.description}</div>
					</CardAnalyticsBody>
				</CardAnalytics>
			</GridItem>
		);
});


function AnalyticsIdeas_cards(props) {
	const { t, i18n } = useTranslation();
	const { classes, content } = props;
	const [load, setLoad] = useState(false);

	const renderIdeaCard = (idea, indexIdea) => (
        <IdeaCard
            key={idea.ideaid}
            idea={idea}
            departments={props.departments}
            editLabelModal={props.editLabelModal}
            deleteLabelModal={props.deleteLabelModal}
            addLabelModal={props.addLabelModal}
            deleteIdeaModal={props.deleteIdeaModal}
            handleChangeCheckbox={props.handleChangeCheckbox}
            selectedIdeas={props.selectedIdeas}
            classes={classes}
            isArchived={props.isArchived}
			indexIdea={indexIdea}
        />
    );

	return (
			<PaginatedItems
				itemsPerPage={40}
                items={props.ideas}
                renderItem={renderIdeaCard} />
	);
}


export default withStyles(
	(theme) => ({
		...analyticsStyle(theme),
		...useStyles(theme),
	}),
	{ withTheme: true }
)(AnalyticsIdeas_cards);
