/*!
 * Description: this file contains a component
 *  that renders a disabled lookalike button that contains numbers or text
 */

import React, { useState, useEffect, useRef } from "react";
import withStyles from "@mui/styles/withStyles";
import LoadingOverlay from "react-loading-overlay";
import BounceLoader from "react-spinners/BounceLoader";
import jwt from "jwt-decode";
import analyticsStyle from "assets/jss/views/analyticsStyle.jsx";
import {
	n_comp_pck,
	isAuthenticated,
	actions_post
} from "assets/jss/incept-sustainability-variables.jsx";

import AnalyticsCompany_Header from "views/Analytics/AnalyticsCompany/AnalyticsCompany_Header.jsx";
import AnalyticsCompany_FilterBar from "views/Analytics/AnalyticsCompany/AnalyticsCompany_FilterBar.jsx";
import AnalyticsCompany_card from "views/Analytics/AnalyticsCompany/AnalyticsCompany_card.jsx";
import loadinggif from "assets/img/loading.gif";
import { withTranslation, Trans,useTranslation } from 'react-i18next';

import axios from "axios";

//Translations
import i18n from "i18next";
import i18next from "i18next";

const useStyles = (theme) => ({
	cardPaddingRight: ({ color, textColor }) => ({
		marginRight: "30px",
		"@media only screen and (max-width: 960px)": {
			marginRight: "15px",
		},
	}),
});

function AnalyticsCompany(props) {
	const { classes, content } = props;
	var decodedtoken = "";
	var redi = false;
	if (!isAuthenticated()) {
		redi = true;
	} else decodedtoken = jwt(localStorage.getItem("token"));
	let roleaux = decodedtoken.role;
	const [isCustomManager, setCustomManager] = useState(
		(roleaux === "Custom Manager" || roleaux === "Custom Learner Manager") ? true : false
	);
	const showGeographyFilter = decodedtoken.hasgeography;
	const limitresults = decodedtoken.limitresults;
	const [departments, setDepartments] = useState([]);
	const [countries, setCountries] = useState([]);
	const [answersCompany, setCompanyAnswers] = useState([]);
	const [answersCompanyFilter, setCompanyAnswersFilter] = useState([]);
	const [submittingnewaction, setsubmittingnewaction] = useState(false);
	const [filterFlags, setFilterFlags] = useState({
		module: 0,
		department: 0,
		question: 0,
		country: 0,
	});
	const [modules, setModules] = useState([]);
	const [activities, setActivities] = useState([]);
	const [activitiesFiltered, setActivitiesFiltered] = useState([]);
	const [currentDepartment, setcurrentDepartment] = useState(0);
	const [currentCountry, setcurrentCountry] = useState([]);
	const [selectedDepartment, setselectedDepartment] = useState(0);
	const [selectedModule, setselectedModule] = useState("0");
	const [selectedCountry, setselectedCountry] = useState([]);
	const [currentActivity, setcurrentActivity] = useState("0");
	const [currentModule, setcurrentModule] = useState("0");
	const [managers, setManagers] = useState([]);
	const [addActionModalValue, setaddActionModalValue] = useState(false);
	const [overview, setOverview] = useState({
		avg_submission: 0,
		pempty: 0,
		pempty_mult: 0,
		pempty_free: 0,
		pempty_dd: 0,
		pempty_rank: 0,
	});
	const [loadingfirst, setloadingfirst] = useState(true);
	const multiselectRef = useRef(null);
	const { t, i18n } = useTranslation();

	function resetValues() {
		// By calling the belowe method will reset the selected values programatically
		multiselectRef.current.resetSelectedValues();
	}

	useEffect(() => { }, [answersCompanyFilter]);

	useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (loadingfirst) {
			getncomplpack(currentDepartment);
		}
	}, []);

	useEffect(() => {
		// You need to restrict it at some point
		// This is just dummy code and should be replaced by actual
		if (parseInt(currentDepartment) !== 0) {
			filterModule(currentModule);
			filterActivity(currentActivity);
		}
	}, [answersCompany]);

	//Get ideas from backend
	const getncomplpack = async (depid, countryid = 0, isfilter = 0) => {
		//readMore1.data.series = [[9, 9, 9, 9, 9, 27, 50,21]]
		if (!isAuthenticated()) {
			props.isExpired();
		}
		if (isfilter) {
			removeHash();
		}

		const token = localStorage.getItem("token");
		const authstr = "Bearer " + token;
		const useLang = i18n.language;

		const insdata = isfilter ? 
		{
			departmentid: depid,
			countryid: JSON.stringify(countryid),
			module: selectedModule
		} :
		{
			departmentid: depid,
			module: selectedModule
		};
		
		axios.get(n_comp_pck + "answers?wpml_language=" + useLang, { headers: { Authorization: authstr }, params: insdata })
			.then(response => {
				//(Guide 1) For key opportunities
				console.log(response);
				setCompanyAnswers(response.data.company);
				setCompanyAnswersFilter(response.data.company);
				setOverview({
					avg_submission: response.data.avg_submission,
					pempty: response.data.pempty_all,
					pempty_mult: response.data.pempty_multichoice,
					pempty_free: response.data.pempty_freetext,
					pempty_dd: response.data.pempty_dragdrop,
					pempty_rank: response.data.pempty_ranking,
				});
				setActivities(response.data.activities);
				setActivitiesFiltered(response.data.activities);
				if (isfilter) {
					setFilterFlags({ ...filterFlags, country: 1, department: 1 });	
				}
				else {
					setDepartments(response.data.departments);
					setCountries(response.data.countries);
					setModules(response.data.modules);
					setManagers(response.data.managers);
					var index = window.location.href.indexOf("#");
					if (index !== -1) {
						var hash = window.location.href.substring(index + 1);
						document.getElementById(hash).scrollIntoView();
					}
				}				
				setloadingfirst(false);

			})
			.catch(err => {
				console.log(err);
			})
	}

	function removeHash() {
		history.pushState("", document.title, window.location.pathname
			+ window.location.search);
	}

	const filterData = (type, id) => {
		if (type === "freeText") {
			//getncomplpack("freeText",id);
		}
	};

	const filterSectionsDep = (event) => {
		setselectedDepartment(event.target.value);
	};

	const filterSectionsMod = (event) => {
		setselectedModule(event.target.value);
	};

	function filter_country_multichoice(selectedList, selectedItem) {
		let selectedListCopy = JSON.parse(JSON.stringify(selectedList));
		setselectedCountry(selectedListCopy);
	}

	const filterSections = (event, type) => {
		if (type === "Activity") {
			let questionid = event.target.value;
			setcurrentActivity(questionid);
		}
	};
	// //add Modal action page

	// const addActionModal = () => {
	//   //open modal
	//   setaddActionModalValue(!addActionModalValue);
	// };
	//For search button

	const filterModule = (answers) => {
		//setcurrentModule(moduleid);
		let moduleid = currentModule;
		let filterAnswers_aux = answers;
		if (moduleid === "All") {
			filterAnswers_aux = answersCompany;
			setFilterFlags({ ...filterFlags, module: 0 });
		} else {
			filterAnswers_aux = answersCompany.filter(function (e) {
				return e.module_name == moduleid;
			});
			setFilterFlags({ ...filterFlags, module: 1 });
		}
		return filterAnswers_aux;
	};

	const filterActivity = (answers) => {
		//setcurrentActivity(questionid);
		let questionid = currentActivity;
		let filterAnswers_aux = answers;
		if (questionid === "0") {
			filterAnswers_aux = answersCompany;
			setFilterFlags({ ...filterFlags, question: 0 });
		} else {
			filterAnswers_aux = answersCompany.filter(function (e) {
				return e.exerciseid == questionid;
			});
			setFilterFlags({ ...filterFlags, question: 1 });
		}
		return filterAnswers_aux;
	};

	const objectsEqual = (o1, o2) => {
		if (o2 === null && o1 !== null) return false;
		return o1 !== null && typeof o1 === "object" && Object.keys(o1).length > 0
			? Object.keys(o1).length === Object.keys(o2).length &&
			Object.keys(o1).every((p) => objectsEqual(o1[p], o2[p]))
			: o1 !== null &&
				Array.isArray(o1) &&
				Array.isArray(o2) &&
				!o1.length &&
				!o2.length
				? true
				: o1 === o2;
	};

	function filter_results() {
		if (
			objectsEqual(currentCountry, selectedCountry) &&
			currentDepartment === selectedDepartment && currentModule === selectedModule
		) {
			//only filter by activity
			if (currentModule === "0") {
				return;
			}
			let filterAnswers_aux = activitiesFiltered;
			//filterAnswers_aux = filterModule(filterAnswers_aux);
			filterAnswers_aux = filterActivity(filterAnswers_aux);
			setCompanyAnswersFilter(filterAnswers_aux);
			console.log(activitiesFiltered);
		} else {
			//filter by country and/or department and reset activity list and module list
			setloadingfirst(true);
			setcurrentModule("0");
			setcurrentActivity("0");
			setcurrentModule(selectedModule);
			setcurrentCountry(selectedCountry);
			setcurrentDepartment(selectedDepartment);
			setActivitiesFiltered(activities);
			setFilterFlags({ ...filterFlags, question: 0 });
			getncomplpack(selectedDepartment, selectedCountry, 1);
			//getncomplpack(currentDepartment,currentCountry);
		}
	}

	function clearFilters() {
		resetValues();
		setcurrentModule("0");
		setcurrentActivity("0");
		setcurrentCountry([]);
		setActivities([]);
		setActivitiesFiltered([]);
		setcurrentDepartment(0);
		setselectedCountry([]);
		setselectedModule("0");
		setselectedDepartment(0);
		setCompanyAnswersFilter([]);
		/*if (filterFlags.country === 1 || filterFlags.department === 1) {
			setloadingfirst(true);
			getncomplpack(0);
		}*/
		setFilterFlags({ module: 0, department: 0, question: 0, country: 0 });
	}

	const submitNewAction = (modalactionTitle,modalactionDescription,modalactioninfo,currentActivity,activityindex) => {
		if (modalactionTitle == null || modalactionTitle == "") {
			//setnewLabel(false);
		} else {
			setsubmittingnewaction(true);

			if (!isAuthenticated()) {
				props.isExpired();
			}

			const insdata = {
				title: modalactionTitle,
				description: modalactionDescription,
				department: modalactioninfo.market,
				responsible: modalactioninfo.responsible,
				completedby: modalactioninfo.compltedby.toDateString(),
				activityid: currentActivity,
				activityindex: activityindex,
				type: "activity"
			};

			const token = localStorage.getItem("token"); //token needed
			const authstr = "Bearer " + token;
			//post
			axios
				.post(actions_post + "add", insdata, {
					headers: { Authorization: authstr },
				})
				.then((res) => {
					console.log(res);
					//Ad "Action" label to answersCompanyFilter and answersCompany
					let action = res.data[0];
					let newActivityArray = answersCompany;
					newActivityArray[parseInt(action.index)].isaction = 1;
					setCompanyAnswers(newActivityArray);
					let newActivityArrayFilter = answersCompanyFilter;
					newActivityArrayFilter[parseInt(action.index)].isaction = 1;
					setCompanyAnswersFilter(newActivityArrayFilter);					
					
					setsubmittingnewaction(false);
					setaddActionModalValue(false);
					let {title,description,market,responsible, ...restItem} = modalactioninfo;
				})
				.catch((err) => {
					console.log(err);
					setsubmittingnewaction(false);
					
				});
		}
	}

	return (
		<LoadingOverlay active={loadingfirst} spinner={<BounceLoader />}>
			<div className={classes.containerAnalytics} id="testscroll">
				<h2 className={classes.titleHeader}>{t("Employee feedback")}</h2>
				<div className={classes.cardPaddingRight}>
					<AnalyticsCompany_Header
						departments={departments}
						overview={overview}
					/>
				</div>
				<div style={{ margin: "50px 0 20px 0" }}>
					<AnalyticsCompany_FilterBar
						modules={modules}
						isCustomManager={isCustomManager}
						departments={departments}
						showGeographyFilter={showGeographyFilter}
						countries={countries}
						activities={activitiesFiltered}
						currentDepartment={selectedDepartment}
						currentCountry={selectedCountry}
						currentModule={selectedModule}
						currentActivity={currentActivity}
						showlimitmessage={limitresults}
						filterSections={filterSections}
						filterSectionsDep={filterSectionsDep}
						filterSectionsMod={filterSectionsMod}
						filterSectionsCountry={filter_country_multichoice}
						clearFilters={clearFilters}
						searchFilter={filter_results}
						multiselectRef={multiselectRef}
					/>
				</div>
				{answersCompanyFilter.length > 0 && (
					<div style={{ margin: "0 30px 50px 0" }}>
						<AnalyticsCompany_card
							answersCompany={answersCompanyFilter}
							filterData={filterData}
							departments={departments}
							managers={managers}
							filter={{department: currentDepartment, country: currentCountry}}
							submitNewAction={submitNewAction}
							submittingnewaction={submittingnewaction}
							addActionModalValue={addActionModalValue}
							setaddActionModalValue={setaddActionModalValue}
							isCustomManager={isCustomManager}
						/>
					</div>
				)}
				{answersCompanyFilter.length === 0 && currentModule==="0" && (
					<div style={{ margin: "40px 30px 50px 0px", textAlign: "center" }}>
							<h4>{"Select a module to see the answers"}</h4>
					</div>
				)}
			</div>
		</LoadingOverlay>
	);

}
export default withStyles(
	(theme) => ({
		...analyticsStyle(theme),
		...useStyles(theme),
	}),
	{ withTheme: true }
)(AnalyticsCompany);
